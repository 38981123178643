import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressAutocompleteService {

  constructor(private http: HttpClient) { }

  search(query: string): Observable<any> {
    const url = `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&access_token=pk.eyJ1IjoicmFuaWtoYXJzYSIsImEiOiJjbTBjaDI3cHEwMjgwMm1zY3M5aXhncmdxIn0.eovLyOOE2JRycBC3WWVF9Q&limit=5&language=fr`;
    return this.http.get(url);
  }

}
