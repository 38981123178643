<mat-form-field appearance="outline" [class]="classNames">
  <mat-label>{{ 'website.datepicker_range_preset.period' | translate }}</mat-label>

  <mat-date-range-input [formGroup]="range" [rangePicker]="picker" >
    <input matStartDate formControlName="start" placeholder="Start date"  >
    <input matEndDate formControlName="end" placeholder="End date" >
  </mat-date-range-input>

  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker     [calendarHeaderComponent]="DatepickerPresetHeaderComponent" #picker>
    <mat-date-range-picker-actions>
      <button mat-button matDateRangePickerCancel>{{ 'website.datepicker_range_preset.cancel' | translate }}</button>
      <button
        mat-raised-button
        color="primary"
        (click)="applySelectedDate(picker);"
        [disabled]="dateRangePickerService.applyDisabled | async"
        matDateRangePickerApply >
        {{ 'website.datepicker_range_preset.apply' | translate }}
      </button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>

</mat-form-field>
