import { Component, OnDestroy, OnInit, forwardRef, Input, DestroyRef, inject } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AddressAutocompleteService } from '@shared/services/address-autocomplete.service';
import { of, Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddressAutocompleteComponent),
    multi: true
  }]
})
export class AddressAutocompleteComponent implements ControlValueAccessor, OnDestroy, OnInit {
  constructor(private addressService: AddressAutocompleteService) {}
  search: FormControl = new FormControl();
  addresses: any[] = [];
  @Input() classList = '';
  subscriptions: Subscription = new Subscription();
  private isDisabled = false;
  private destroyRef = inject(DestroyRef);
  private onChange: any = () => {};
  private onTouched: any = () => {};


  public ngOnInit(): void {
    this.onSearch();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onSearch(): void {
    const searchValueChange = this.search.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(value => {
        if (value?.length > 2) {
          return this.addressService.search(value);
        } else {
          this.addresses = [];
          return of([]);
        }
      })
    ).subscribe(data => {
      this.addresses = data.features;
    });
    this.subscriptions.add(searchValueChange);
  }

  public onSelectAddress(event: MatAutocompleteSelectedEvent): void {
    const selectedValue = event.option.value;
    this.search.setValue(selectedValue);
    this.onChange(selectedValue);
    this.onTouched();
  }


  writeValue(value: any): void {
    if (value) {
      this.search.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.search.disable();
    } else {
      this.search.enable();
    }
  }
}
