import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { take } from 'rxjs/operators';
import { AppInjector } from 'src/app/app.injector';
import { GraphqlService } from 'src/app/graphql/graphql.service';

import { Collection } from '../../models/collection/collection';
import { BuildOperations } from './build.operations';
import { ICrudService } from './icrud.interface';

@Injectable()
export abstract class CrudService<Model, CreateInput, UpdateInput>
  implements ICrudService<Model, CreateInput, UpdateInput> {
  public abstract fragment: any;
  public abstract modelName: string;
  protected _operations: BuildOperations;
  private _graphql: GraphqlService;

  public async retrieve(
    one: boolean,
    criteria: { id: string } | { criteria: any }
  ): Promise<Collection<Model> | Model> {
    try {
      const data = await this.graphql
        .query<ApolloQueryResult<Model[] | Model>>(this.operations.buildQuery(one, criteria), criteria)
        .pipe(take(1))
        .toPromise();
      return data.data[this.operations.getQueryName(one)];
    } catch (error) {
      return one ? ({} as Model) : (({ items: [], meta: { total: 0 } } as unknown) as Collection<Model>);
    }
  }

  public async retrieveAll(): Promise<Collection<Model> | Model> {
    try {
      const data = await this.graphql
        .query<ApolloQueryResult<Model[] | Model>>(this.operations.buildQuery(false, undefined), {})
        .pipe(take(1))
        .toPromise();
      return data.data[this.operations.getQueryName(false)];
    } catch (error) {
      return { items: [], meta: { total: 0 } } as Collection<Model>;
    }
  }

  public async create(input: CreateInput): Promise<Model> {
    try {
      const data = await this.graphql.mutate(this.operations.buildSaveMutation(true), { input }).toPromise();
      return data.data['create' + this.modelName];
    } catch (error) {
      return {} as Model;
    }
  }

  public async update(input: any): Promise<Model> {
    try {
      const data = await this.graphql.mutate(this.operations.buildSaveMutation(false), { input }).toPromise();
      return data.data['update' + this.modelName];
    } catch (error) {
      return {} as Model;
    }
  }
  public async delete(id: any): Promise<Model> {
    try {
      const data = await this.graphql.mutate(this.operations.buildDeleteMutation(), { id }).toPromise();
      return data.data['delete' + this.modelName];
    } catch (error) {
      return {} as Model;
    }
  }

  protected get operations(): BuildOperations {
    if (!this._operations) {
      this._operations = new BuildOperations(this.fragment, this.modelName);
    }
    return this._operations;
  }

  protected get graphql(): GraphqlService {
    if (!this._graphql) {
      const injector = AppInjector.getInjector();
      this._graphql = injector.get(GraphqlService);
    }
    return this._graphql;
  }
}
