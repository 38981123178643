import { ChangeDetectorRef, Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LanguageService } from '@shared/services/language.service';
import { Permission } from '@users/domain/services/user.enums';
import { LoginStatusUpdate, UserService } from '@users/domain/services/user.service';
import { UserInfo } from '@users/domain/services/user.interfaces';
import { TokenSessionService } from '@shared/services/token.session.service';
import { ProfileService } from '@shared/services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { EquipmentAlarmsChange } from '@equipment/services/equipment-alarms-change.interface';
import { EquipmentService } from '@equipment/services/equipment.service';
import { EquipmentDetailsService } from '@equipment/services/equipment-details.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


export class CountAlarm {
  public totalEquipmentsWithActiveAlarms: number;
  public totalEquipmentsWithConnectionAlarm?: number;
}

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  title = 'material-responsive-sidenav';
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  destroyRef = inject(DestroyRef);
  isMobile = false;
  isCollapsed = true;
  crtLang: string = null;
  itemsSideBar: {
    hasToDisplayAfterPermissionFilter?: boolean;
    items: {
      hide?: boolean,
      icon: string,
      isMaterialIcon?: boolean,
      onClick?: () => void,
      title: string,
      isExternalLink?: boolean,
      ariaLabel: string,
      link?: string | string[],
      disabled?: boolean,
      hasPermissions?: string[]
    }[]
  }[] = [];
  hide = true;
  token: string = null;
  _userInfo: UserInfo = null;
  public totalEquipmentsHavingAlarms: CountAlarm;
  nameInitial: string;

  constructor(private equipmentDetailsService: EquipmentDetailsService, private equipmentService: EquipmentService,
              public changeDetectorRef: ChangeDetectorRef, private router: Router, private tokenSessionService: TokenSessionService,
              private profileService: ProfileService, private observer: BreakpointObserver, private languageService: LanguageService,
              private activatedRoute: ActivatedRoute,
              private profilService: ProfileService, private userService: UserService) {
    this.crtLang = this.languageService.currentLanguage;
    const isCollapse = localStorage.getItem('sidebar-is-collapse');

    if (isCollapse !== undefined && !JSON.parse(isCollapse)) {
      this.isCollapsed = false;
    }
    this.itemsSideBar = [
      {
        items: [
          {
            title: 'website.sidebar.dashboard',
            icon: 'custom-icon-layout',
            link: ['/', this.crtLang, 'dashboard'],
            ariaLabel: 'sidebar-dashboard'
          },
          {
            title: 'website.sidebar.equipment',
            icon: 'custom-icon-command',
            link: ['/', this.crtLang, 'equipment'],
            ariaLabel: 'sidebar-equipment-list'
          }
        ]
      },
      {
        items: [
          {
            title: 'website.sidebar.users',
            icon: 'custom-icon-users',
            link: ['/', this.crtLang, 'users'],
            ariaLabel: 'sidebar-users-list'
          },
          {
            title: 'website.sidebar.shops',
            icon: 'custom-icon-location',
            link: ['/', this.crtLang, 'customer-locations'],
            ariaLabel: 'sidebar-customer-locations'
          }]
      },
      {
        items: [
          {
            title: 'website.sidebar.device_management',
            icon: 'build',
            isMaterialIcon: true,
            link: ['/', this.crtLang, 'admin', 'devices-details'],
            hasPermissions: [Permission.MatelexAdminPermission],
            ariaLabel: 'sidebar-device-details'
          },
          {
            title: 'website.sidebar.installers',
            icon: 'engineering',
            isMaterialIcon: true,
            link: ['/', this.crtLang, 'installers'],
            hasPermissions: [Permission.MatelexAdminPermission],
            ariaLabel: 'sidebar-installers'
          },
          {
            title: 'website.sidebar.installer_chains',
            icon: 'share',
            isMaterialIcon: true,
            link: ['/', this.crtLang, 'installer-chains'],
            hasPermissions: [Permission.MatelexAdminPermission],
            ariaLabel: 'sidebar-installer-chains'
          },
          {
            title: 'website.sidebar.store_chains',
            icon: 'source_environment',
            isMaterialIcon: true,
            link: ['/', this.crtLang, 'customer-chains'],
            hasPermissions: [Permission.MatelexAdminPermission],
            ariaLabel: 'sidebar-store-chains'
          },
          {
            title: 'website.sidebar.administration',
            icon: 'admin_panel_settings',
            isMaterialIcon: true,
            link: this.adminUrl(),
            isExternalLink: true,
            hasPermissions: [Permission.MatelexAdminPermission],
            ariaLabel: 'sidebar-administration'
          }
        ]
      },
      {
        items: [{
          title: 'website.sidebar.help_needed',
          icon: 'help_outline',
          isMaterialIcon: true,
          hasPermissions: [],
          link: 'https://www.matelex.com/centre-de-ressources/',
          isExternalLink: true,
          ariaLabel: 'sidebar-help'
        }]
      }
    ];

    this.observer.observe(['(max-width: 800px)']).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((screenSize) => {
      this.isMobile = screenSize.matches;
    });
  }


  async ngOnInit(): Promise<void> {
    this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);


    this.equipmentDetailsService.equipmentAlarmsChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (equipmentAlarmsChange: EquipmentAlarmsChange) => {
      if (equipmentAlarmsChange?.activeAlarmsCountChanged) {
        console.log(
          'SidebarComponent ngOnInit this.equipmentDetailsService.equipmentAlarmsChanged equipmentAlarmsChange',
          equipmentAlarmsChange
        );
        this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);
      }
    });


    this.userService.getMyToken().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      res => {

        this.token = res;
      },
      err => console.log('SideBarComponent : Error in fetching token', err)
    );

    this.userService.loginStatusUpdates.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (loginStatusUpdate: LoginStatusUpdate) => {
      console.log('HeaderComponent ngOnInit this.userService.loginStatusUpdates.subscribe loginStatusUpdate', loginStatusUpdate);
      this.refreshUserInfo(loginStatusUpdate);
      if (this.tokenSessionService.isAuthenticated()) {
        await this.userService.retrieveNotifications();
        const featuresFlags = (await this.userService.retrieveFeaturesFlag());
        localStorage.setItem(btoa('featuresFlag'), btoa(JSON.stringify(featuresFlags)));
        this.changeDetectorRef.detectChanges();
        this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);
      }
    });
    this.activatedRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (params) => {
      if (params.asUser && this.profilService.isAuthenticated() && this.profilService.hasPermissions([Permission.MatelexAdminPermission])
        && this.profilService.userInfo?.email !== params.asUser) {

        await this.userService.adminAsUser(params.asUser);

      }

    });
    this.refreshUserInfo();
  }


  toggleMenu(): void {
    if (this.isMobile) {
      this.sidenav.toggle();
      this.isCollapsed = false;
    } else {
      this.sidenav.open();
      this.isCollapsed = !this.isCollapsed;
    }
    localStorage.setItem('sidebar-is-collapse', String(this.isCollapsed));
  }

  public refreshUserInfo(loginStatusUpdate?: LoginStatusUpdate): void {
    this._userInfo = loginStatusUpdate ? loginStatusUpdate.userInfo : this.profileService.userInfo;
    this.nameInitial = this._userInfo?.firstName?.[0] + this._userInfo?.lastName?.[0];
    this.itemsSideBar.map((item) => {
      item.hasToDisplayAfterPermissionFilter = item.items?.some((nav) => {
        const hasPermissions = nav.hasPermissions
          ? this.userService.loggedInUserHasPermissions(nav.hasPermissions as Permission[])
          : true;
        const isVisible = !nav.hide;

        return hasPermissions && isVisible;
      });

      return item;
    });

  }

  public get userInfo(): UserInfo {
    if (!this._userInfo) {
      this._userInfo = this.profileService.userInfo;
    }
    this.nameInitial = this._userInfo.firstName?.[0] + this._userInfo.lastName?.[0];
    return this._userInfo;
  }

  public logout(): void {
    this.tokenSessionService.logout();
  }


  public goToDashboard(): void {
    this.router.navigate([this.languageService.currentLanguage, 'dashboard']);
  }

  public adminUrl(): string {
    let port = '';
    if (window.location.hostname === 'localhost') {
      port = environment.kickstartFrontendPort;
    }
    return window.location.protocol +
      '//' +
      window.location.hostname +
      port +
      environment.urlPrefix +
      '/' +
      this.languageService.currentLanguage +
      '/admin';
  }

  toggleForToken(): void {
    if (this.isCollapsed) {
      this.toggleMenu();
      this.hide = false;
    }
  }

  public async reloadCounterAlarms(): Promise<void> {
    this.refreshUserInfo();
    if (this.tokenSessionService.isAuthenticated()) {
      this.totalEquipmentsHavingAlarms = await this.equipmentService.countEquipmentsHavingAlarms(true);
    }
  }
}
