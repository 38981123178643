import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class DateRangePickerService {
  public applyDisabled = new BehaviorSubject(false);
  public customPresets: string[] = [
    'today',
    'last_7_days',
    'last_14_days',
    'last_30_days',
    'previous_month',
    'previous_6_months',
    'previous_12_months',
  ];
}
