import { BaseModel } from './base.model';
import { InstallerChain } from './installer-chain';
import { User } from '@users/domain/models/user';

export class Installer extends BaseModel {
  public name: string;
  public chain: InstallerChain|string;
  public phone: string;
  public email: string;
  public country: string;
  public postalCode: string;
  public city: string;
  public streetAndNumber: string;
  public logo: string;
  public manager: User |string;

  constructor(valuesObj: any) {
    super(valuesObj);
    if (valuesObj.chain) {
      this.chain = new InstallerChain(valuesObj.chain);
    }
  }
}
