<mat-form-field   [appearance]='appearance'  [class]='classList' subscriptSizing='dynamic' >
  <mat-label>{{label}}</mat-label>
  <input type="text" matInput [formControl]="control" [matAutocomplete]="auto"  [disabled]='disabled'>

  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
  <mat-autocomplete [requireSelection]="requiredSelection" [panelWidth]="'auto'"   #auto="matAutocomplete"  [displayWith]="displayFn"  (optionSelected)='optionSelectedEvent($event)'>
    <ng-container  *ngIf='!loadingInProgress'>
      <mat-option   [value]="null" *ngIf="allowEmptyValue">

      </mat-option>
      <mat-option   *ngFor="let option of filteredOptions | async" [value]="getValueFromPathObject(this.option,bindValue)">
        {{translateLabel+getValueFromPathObject(this.option,bindLabel)|translate}}
      </mat-option>
    </ng-container>

    <mat-option *ngIf='loadingInProgress'> <div class="d-flex p-2"><mat-spinner matSuffix   diameter='18' class="me-2 mt-1"  >spinner</mat-spinner> Loading...</div> </mat-option>
  </mat-autocomplete>
</mat-form-field>
