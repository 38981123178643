import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar, MatDateRangePicker } from '@angular/material/datepicker';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-range-panel',
  templateUrl: './custom-range-panel.component.html',
  styleUrls: ['./custom-range-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomRangePanelComponent<D> implements OnInit, OnDestroy{
  @Input() customPresets: string[] = [];
  @HostBinding('class.touch-ui') readonly isTouchUi = this.picker.touchUi;
  selectedRange = '';
  private readonly destroy$ = new Subject<void>();

  constructor(
    private dateAdapter: DateAdapter<D>,
    private picker: MatDateRangePicker<D>,
    private calendar: MatCalendar<D>,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      cdr.markForCheck();
      this.loadSelectedRange();
    });
  }

  ngOnInit() {
    this.loadSelectedRange();
  }

  loadSelectedRange() {
    this.selectedRange = '';
    for (const preset of this.customPresets) {
      if (this.isSelectedRange(preset)) {
        this.selectedRange = preset;
        break;
      }
    }
  }

  // called when user selects a range preset:
  selectRange(rangeName: string): void {
    if (rangeName === 'Custom') { return; }
    const [start, end] = this.calculateDateRange(rangeName);
    (this.calendar.selected as any).start = start;
    (this.calendar.selected as any).end = end;
    this.calendar.updateTodaysDate();
    this.calendar.activeDate = start;
    this.calendar.activeDate = end;
    //this.picker.select(start);
    //this.picker.select(end);
    //this.picker.close();
  }

  private calculateDateRange(rangeName: string): D[] {
    const today = this.today;
    const year = this.dateAdapter.getYear(today);

    switch (rangeName) {
      case 'Today':
        return [this.today, this.today];
      case 'last_7_days': {
        const start = this.dateAdapter.addCalendarDays(today, -6);
        return [start, today];
      }
      case 'last_14_days': {
        const start = this.dateAdapter.addCalendarDays(today, -13);
        return [start, today];
      }
      case 'last_30_days': {
        const start = this.dateAdapter.addCalendarDays(today, -29);
        return [start, today];
      }
      case 'This month': {
        return this.calculateMonth(today);
      }
      case 'previous_month': {
        const thisDayLastMonth = this.dateAdapter.addCalendarMonths(today, -1);
        return this.calculateMonth(thisDayLastMonth);
      }
      case 'previous_6_months': {
        const thisDayLastMonth = this.dateAdapter.addCalendarMonths(today, -6);
        return [thisDayLastMonth, today];
      }
      case 'previous_12_months': {
        const thisDayLastMonth = this.dateAdapter.addCalendarMonths(today, -12);
        return [thisDayLastMonth, today];
      }
      default: {
        return [today, today];
      }
    }
  }

  private calculateMonth(forDay: D): D[] {
    const year = this.dateAdapter.getYear(forDay);
    const month = this.dateAdapter.getMonth(forDay);
    const start = this.dateAdapter.createDate(year, month, 1);
    const end = this.dateAdapter.addCalendarDays(
      start,
      this.dateAdapter.getNumDaysInMonth(forDay) - 1
    );
    return [start, end];
  }

  private get today(): D {
    const today = this.dateAdapter.today();
    if (today === null) {
      throw new Error('date creation failed');
    }
    return today;
  }


  ngOnDestroy():void {
    this.destroy$.next();
  }

  get selectedDateRange() {
    const selectedRange: any = this.calendar.selected;
    let displayText = '';
    if (selectedRange.start !== null)
      displayText += new Date(String(selectedRange.start)).toLocaleDateString();
    if (selectedRange.end !== null)
      displayText +=
        ' - ' + new Date(String(selectedRange.end)).toLocaleDateString();
    return displayText;
  }
  isSelectedRange(rangeName: string): boolean {
    const [start, end] = this.calculateDateRange(rangeName);
    const selectedRange: any = this.calendar.selected;
    return (
      selectedRange.start !== null &&
      selectedRange.end !== null &&
      new Date(String(selectedRange.start)).toDateString() ===
      new Date(String(start)).toDateString() &&
      new Date(String(selectedRange.end)).toDateString() ===
      new Date(String(end)).toDateString()
    );
  }
}
